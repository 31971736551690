<template>
  <section class="treatment-plan">
    <div>
      <div class="content-devx-no-margin margin-top-block margin-bottom-block position-relative">
        <div class="card-header title-top">
          <span>{{ $t('treatment.treatmentPlan.title') }}</span>
        </div>
        <div class="card-body">
          <DxDataGrid
            class="data-grid-reference-value"
            :data-source="items"
            :row-alternation-enabled="true"
            :show-borders="true"
            :column-auto-width="true"
            @toolbar-preparing="onToolbarPreparing($event)"
          >
            <DxPaging :page-size="3" />
            <DxPager
              :show-page-size-selector="true"
              :allowed-page-sizes="pageSizes"
              :show-info="true"
              info-text="{2} items"
            />
            <DxSearchPanel
              :visible="true"
              :width="200"
              :highlight-case-sensitive="false"
              :placeholder="$t('Search_placeholder')"
            />
            <DxColumn
              data-field="init_date"
              :min-width="200"
              :allow-sorting="false"
              :caption="$t('treatment.treatmentPanel.date')"
              :calculate-display-value="formatingDate"
            />
            <DxColumn
              data-field="description"
              :min-width="200"
              :allow-sorting="false"
              :caption="$t('treatment.treatmentPanel.description')"
            />
            <DxColumn
              data-field="products"
              :allow-sorting="false"
              :caption="$t('treatment.treatmentPanel.product')"
            />
            <DxColumn
              cell-template="statusColumnTemplate"
              fixed-position="right"
              :fixed="true"
              :min-width="100"
              :caption="$t('treatment.treatmentPanel.status')"
              alignment="center"
              :allow-sorting="false"
            />
            <DxColumn
              cell-template="buttonsColumnTemplate"
              alignment="center"
              :fixed="gridSettings.fixed"
              :fixed-position="gridSettings.fixedPosition"
            />
            <template #buttonsColumnTemplate="{ data: cellData }">
              <div class="action-buttons">
                <DxButton
                  styling-mode="text"
                  class="dx-button dx-widget dx-button-has-icon dx-button-mode-text button-search"
                  type="success"
                  icon="search"
                  @click="viewItem(cellData.key)"
                />
                <DxButton
                  styling-mode="text"
                  class="dx-button dx-widget dx-button-has-icon dx-button-mode-text button-edit"
                  icon="edit"
                  @click="editItem(cellData.key)"
                />
                <DxButton
                  styling-mode="text"
                  type="danger"
                  icon="trash"
                  @click="deleteItem(cellData.key)"
                />
              </div>
            </template>
            <template #statusColumnTemplate="{ data }">
              <div>
                <DxSwitch
                  :value="switchValue(data.data.status)"
                  :switched-on-text="$t('ACTIVO').toUpperCase()"
                  :switched-off-text="$t('INACTIVO').toUpperCase()"
                  :width="85"
                  @valueChanged="
                    () => {
                      updateState(
                        {
                          newState: !switchValue(data.data.status),
                          id: data.data.id,
                        },
                        data
                      );
                    }
                  "
                />
              </div>
            </template>
          </DxDataGrid>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {
  DxDataGrid, DxColumn, DxPager, DxPaging, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import { DxSwitch } from 'devextreme-vue/switch';
import { treatmentPlanService, treatmentPlanStatus } from '@/services/treatmentPlanService';
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import settings from '../../../js/commonSettings';

export default {
  name: 'TreatmentPlan',
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxPager,
    DxPaging,
    DxSwitch,
    DxSearchPanel,
  },
  props: [],
  data() {
    return {
      items: [],
      pageSizes: [3, 10, 20],
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapGetters('treatmentStepper', ['treatmentPlan']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {
    this.initData();
  },
  methods: {
    async updateState(data, grid) {
      const status = data.newState ? 'ACTIVO' : 'INACTIVO';
      const payload = { status };
      try {
        const treatment = await treatmentPlanService.getById(data.id, this.currentPlantation.companyId);
        await treatmentPlanService.edit(data.id, { ...payload, periods: treatment.periods }, this.currentPlantation.companyId);

        grid.row.data.status = status;
        grid.component.repaintRows(grid.rowIndex);
      } catch (error) {
        if (error === 'date_range_invalid') {
          this.$notifyDX(
            {
              message: this.$t('DateRangeInvalidPlan'),
              width: 550,
            },
            'error',
            3000,
          );
        } else {
          this.$notifyDX(
            {
              message: error,
              width: 550,
            },
            'error',
            3000,
          );
        }
      }
    },
    switchValue(status) {
      return status === 'ACTIVO';
    },
    initData() {
      const dataPlantation = {
        companyId: this.currentPlantation.companyId,
        idPlantation: this.currentPlantation.id,
      };
      treatmentPlanService.getAll(dataPlantation).then((response) => {
        this.items = response.data;
      });
    },
    add() {
      this.initTreatmentPlan();
      this.$f7.views.main.router.navigate('/utilities/treatment/treatment-step-one',
        { reloadCurrent: true });
    },
    viewItem(item) {
      this.setCurrentTreatmentId(item.id);
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_treatment_treatment-detail-plan',
        params: { id: item.id },
      });
      this.$fa.logEvent('Buscador - Tratamientos - Lupa', {
        id: item.id,
        description: item.description,
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },
    editItem(item) {
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_treatment_treatment-step-one',
        params: { id: item.id },
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },
    deleteItem(item) {
      const self = this;
      this.$f7.dialog.create({
        title: this.$t('Confirm_Delete_Title'),
        text: this.$t('Confirm_Delete_Text'),
        closeByBackdropClick: 'true',
        buttons: [
          {
            text: this.$t('No'),
          },
          {
            text: this.$t('Ok'),
            onClick() {
              treatmentPlanService.delete(item.id, self.currentPlantation.companyId).finally(() => {
                self.initData();
              });
            },
          },
        ],
      }).open();
    },
    formatingDate(e) {
      const initDate = moment(e.init_date).format('DD/MM/YYYY');
      const endDate = moment(e.end_date).format('DD/MM/YYYY');

      return `${initDate}  ~  ${endDate}`;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.add();
          },
        },
      });
    },
    ...mapActions('treatmentStepper', ['initTreatmentPlan', 'setCurrentTreatmentId']),
  },
};
</script>
<style src="./treatmentPlan.scss" scoped lang="scss"></style>

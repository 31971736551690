<template>
  <DxChart
    id="chart"
    :data-source="histogramFormatted"
    :customize-point="customizePoint"
    :bar-group-width="100"
    palette="Material"
  >
    <DxTitle :text="''" />
    <DxSeries
      argument-field="x"
      value-field="y"
      :name="`${index} - ${formattedShowingDate}`"
      :show-in-legend="false"
      color="#fff"
      type="bar"
    />
    <DxSeries
      v-for="(serie, index) in getChartSeries"
      :key="index"
      :value-field="serie"
      :name="serie"
      :color="getSeriesColor(index)"
    />
    <DxTooltip
      :enabled="true"
      :z-index="999999"
      :customize-tooltip="customizeTooltip"
    />
    <DxScrollBar v-if="!isForPdf" :visible="true" />
    <DxZoomAndPan v-if="!isForPdf" argument-axis="both" />
    <DxLegend
      vertical-alignment="bottom"
      horizontal-alignment="center"
      item-text-position="right"
      :font="isForPdf ? { size: 16, weight: 500 } : {}"
    />
    <DxArgumentAxis :tick-interval="0.1">
      <DxTick :visible="true" />
    </DxArgumentAxis>
    <DxSize v-if="isForPdf" :height="300" />
  </DxChart>
</template>

<script>
import {
  DxChart, DxSeries, DxTooltip, DxScrollBar, DxZoomAndPan, DxLegend, DxTitle, DxArgumentAxis, DxTick, DxSize,
} from 'devextreme-vue/chart';
import moment from 'moment';

export default {
  name: 'HistogramGraph',
  components: {
    DxChart,
    DxSeries,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxLegend,
    DxTitle,
    DxArgumentAxis,
    DxTick,
    DxSize,
  },
  props: {
    histogram: {
      type: Object,
      default: () => ({}),
    },
    showingDate: {
      type: String,
      default: '',
    },
    index: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    mean: {
      type: Number,
      default: null,
    },
    stddev: {
      type: Number,
      default: null,
    },
    isForPdf: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: ['#f1722b', '#fdae61', '#ffffbf', '#a6d96a', '#1a9641'],
      colorsNDMI: ['#bc2322', '#d99a5c', '#ccce99', '#3fa1bd', '#297099'],
      colorsGLI: ['#5a9542', '#306637', '#19552b', '#104539', '#0b393e'],
      colorsNDVICONTRASTADO: ['#e75437', '#febe74', '#b8e17b', '#74b4fe', '#1674e1'],
      indexLimits: {
        NDVI: {
          max: 1,
          min: 0,
        },
        NDVI_CONT: {
          max: 1,
          min: 0,
        },
        SAVI: {
          max: 1,
          min: 0,
        },
        ARVI: {
          max: 1,
          min: 0,
        },
        VARI: {
          max: 1,
          min: -0.6,
        },
        LAI: {
          max: 1,
          min: 0,
        },
        NDRE: {
          max: 1,
          min: 0,
        },
        RECI: {
          max: 1,
          min: -0.6,
        },
        NDMI: {
          max: 1,
          min: -1,
        },
        GNDVI: {
          max: 1,
          min: 0,
        },
        GLI: {
          max: 1,
          min: 0,
        },
        TCI: {
          max: 1,
          min: -1,
        },
        MSAVI: {
          max: 1,
          min: -0.8,
        },
      }
    };
  },
  computed: {
    histogramFormatted() {
      const auxHistogramFormatted = [];
      const { hist_X, hist_Y } = this.histogram;
      const { indexLimits, index } = this;
      const indexLimit = indexLimits[index];

      for (let i = 0; i < hist_X.length - 1; i += 1) {
        const x = hist_X[i];

        if (x > indexLimit.min && x < indexLimit.max) {
          const { color } = this.getClassAndColor(x);
          auxHistogramFormatted.push({
            x,
            y: (index === 'NDVI_CONT') ? hist_Y[i] : hist_Y[i] / 100,
            color
          });
        }
      }
      return auxHistogramFormatted;
    },
    formattedShowingDate() {
      return moment(this.showingDate).format('DD-MM-YYYY');
    },
    getChartSeries() {
      const formatted = this.histogramFormatted.map((item) => ({
        ...item,
        class: this.getClassAndColor(item.x).class,
      }));
      
      const groupedByClass = formatted.reduce((acc, item) => ({
        ...acc,
        [item.class]: (acc[item.class] || 0) + item.y,
      }), {});

      return Object.keys(groupedByClass);
    },
  },
  watch: {
    // Para actualizar el maximo y minimo del indice
    max(newValue, oldValue) {
      if (newValue !== oldValue && this.index === 'NDVI_CONT') {
        this.updateIndexLimits();
      }
    },
    min(newValue, oldValue) {
      if (newValue !== oldValue && this.index === 'NDVI_CONT') {
        this.updateIndexLimits();
      }
    },
    // Para ejecutarlo cuando cambie el indice ya que en el caso de ndvi a ndvi_cont tiene los mismos datos
    // y no los detectaria solo al cambiar los valores max/min
    index(newValue, oldValue) {
      if (newValue !== oldValue && this.index === 'NDVI_CONT') {
        this.updateIndexLimits();
      }
    },
  },
  methods: {
    // Funcion para actualizar maximo y minimos para el NDVI CONTRASTADO
    updateIndexLimits() {
      // Verificamos que el indice existe
      if (this.indexLimits[this.index]) {
        this.indexLimits[this.index].max = 1;
        this.indexLimits[this.index].min = 0;
      }
    },
    customizeTooltip(pointInfo) {
      let classType;
      if (this.index === 'NDVI_CONT'){
        classType = this.getClassAndColor(pointInfo.argument);
      } else {
        let classRange = parseInt(this.histogram.hist_X.length / 5);
       classType = this.getClassAndColor(pointInfo.argument.toFixed(2), classRange);
      }

      return {
        html: `
          <span class='bold'>${this.$t('class')}: ${classType.class}</span><br>
          <span class='bold'>${this.$t('parcel.detail.area')}: ${pointInfo.value} ha</span>
        `,
      };
    },
    getClassAndColor(value) {
      if (this.index === 'NDVI_CONT') {
        return this.getNDVIContClassAndColor(value);
      } else {
        const { max, min } = this.indexLimits[this.index];
        const range = Math.abs(max - min) / 5;
        const thresholds = Array.from({ length: 6 }, (_, i) => min + i * range);

        const index = thresholds.findIndex((threshold, i) => value <= threshold && value > thresholds[i - 1]) - 1;
        const classLabel = `${thresholds[index].toFixed(2)} - ${thresholds[index + 1].toFixed(2)}`;
        const colorArray = this.index === 'NDMI' ? this.colorsNDMI : (this.index === 'GLI' ? this.colorsGLI : this.colors);

        return { class: classLabel, color: colorArray[index] };
     }
    },
    getNDVIContClassAndColor(value) {
      const { mean, min, max } = this;
      const filt_hist = this.histogram.hist_X.map((x, i) => ({ x, y: this.histogram.hist_Y[i] })).filter((d) => d.x >= 0 && d.x <= 1);

      // Conseguir el valor x del bin mínimo
      const min_h = filt_hist.find((d) => d.y > 0)?.x;

      // Conseguir el valor x del bin máximo
      const max_h = [...filt_hist].reverse().find((d) => d.y > 0)?.x;

      const j_min = (mean - min) / 4;
      const j_max = (max - mean) / 4;

      const n1 = min_h + j_min;
      const n2 = min_h + 3 * j_min;
      const n3 = max_h - 3 * j_max;
      const n4 = max_h - j_max;

      if (value <= n1) {
        return { class: `0 - ${n1.toFixed(2)}`, color: '#e75437' };
      } else if (value <= n2) {
        return { class: `${n1.toFixed(2)} - ${n2.toFixed(2)}`, color: '#febe74' };
      } else if (value <= n3) {
        return { class: `${n2.toFixed(2)} - ${n3.toFixed(2)}`, color: '#b8e17b' };
      } else if (value <= n4) {
        return { class: `${n3.toFixed(2)} - ${n4.toFixed(2)}`, color: '#74b4fe' };
      } else {
        return { class: `${n4.toFixed(2)} - 1`, color: '#1674e1' };
      }
    },
    customizePoint({ data }) {
      return { color: data.color, hoverStyle: { color: data.color } };
    },
    getSeriesColor(index) {
      const colorArray = this.index === 'NDVI_CONT' ? this.colorsNDVICONTRASTADO : (this.index === 'NDMI' ? this.colorsNDMI : (this.index === 'GLI' ? this.colorsGLI : this.colors));
      return colorArray[index];
    },
  },
};
</script>

<style lang="scss" scoped>
@import './HistogramGraph.styles.scss';
</style>
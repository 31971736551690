import moment from 'moment';
import helpers from '../../js/helpers';
import Api from '../../services/Api';

const indexLimits = {
  NDVI: {
    max: 1,
    min: 0,
  },
  NDVI_CONT: {
    max: 1,
    min: 0,
  },
  SAVI: {
    max: 1.5,
    min: 0,
  },
  ARVI: {
    max: 1,
    min: 0,
  },
  VARI: {
    max: 1,
    min: -0.6,
  },
  LAI: {
    max: 1,
    min: 0,
  },
  NDRE: {
    max: 1,
    min: 0,
  },
  RECI: {
    max: 1,
    min: -0.6,
  },
  NDMI: {
    max: 1,
    min: -1,
  },
  GNDVI: {
    max: 1,
    min: 0,
  },
  GLI: {
    max: 1,
    min: 0,
  },
  TCI: {
    max: 1,
    min: -1,
  },
  MSAVI: {
    max: 1,
    min: -0.8,
  },
};

const tasksColors = {
  SIEMBRA: '#fbbf24', // Amarillo
  COSECHA: '#2dd4bf', // Verde menta
  FERTILIZACIÓN: '#db2777',  // Rosa
  'PREPARACIÓN DEL TERRENO': '#f43f5e', // Rojo
  A: '#9c27b0',                       // Púrpura
  'CONTROL DE MALAS HIERBAS': '#4caf50', // Verde
  'CONTROL DE PLAGAS Y ENFERMEDADES': '#2196f3', // Azul
  OTROS: '#ff9800',                   // Naranja
  PODA: '#795548',                    // Marrón
  RIEGO: '#00bcd4',                   // Cian
  'SIEMBRA-TRASPLANTE': '#8bc34a'     // Verde claro
};


async function fetchIndexes({
  location,
  initDate,
  endDate,
  companyId,
  currentIndex,
}) {
  const xhr = await Api.fetchTimelineIndexes(
    location,
    initDate,
    endDate,
    companyId,
  );

  const result = await xhr.json().then((res) => res.data);

  /* Esto lo hacemos para crear una copia exacta de NDVI para NDVI_CONTRASTADO
    ya que tendra los mismos datos solo que cambia el indice y backend no nos lo pasa
  */

  // Iteramos sobre cada objeto en el array
  result.forEach(obj => {
    // Iteramos sobre los valores de cada objeto
    obj.values.forEach(val => {
        // Si el index es "ndvi", creamos una copia con el index cambiado a "ndvi_cont"
        if (val.index === "ndvi") {
            obj.values.push({
                ...val,
                index: "ndvi_cont"
            });
        }
    });
  });

  const indexes = result.map((item) => ({
    date: item.date,
    ...(item.values.find(
      (value) => value.index === currentIndex.toLowerCase(),
    ) ?? {}),
  }));
  return indexes;
}

function formatWeatherData(dataToFormat, valueName, pluviometer = false) {
  const data = {};

  dataToFormat.forEach((item) => {
    const date = item.date.split(' ')[0];
    if (data[date] == null) {
      data[date] = {
        date,
        unit: item.unit,
        value: [item.value],
      };
    } else {
      data[date].value.push(item.value);
    }
  });

  const formattedData = Object.values(data).map((item) => ({
    ...item,
    [valueName]: pluviometer
      ? parseFloat(
        (
          item.value.reduce((acc, cur) => acc + cur, 0) / item.value.length
        ).toFixed(2),
      )
      : parseInt(
        item.value.reduce((acc, cur) => acc + cur, 0) / item.value.length,
        10,
      ),
  }));

  return formattedData;
}

async function fetchWeather({
  companyId,
  plantationId,
  initDate,
  endDate,
  name,
  code,
}) {
  const measures = await Api.getClimateData(
    {
      initDate,
      endDate,
      parameters: [
        'TEMPERATURE',
        'PLUVIOMETER',
        'TEMPERATURE_MAX',
        'TEMPERATURE_MIN',
      ],
      code,
      grouped: 'DAY',
      formula: '',
    },
    companyId,
  );
  const response = JSON.parse(measures.response);

  const temperature = response.measured.parameters.TEMPERATURE ?? [];
  const pluviometer = response.measured.parameters.PLUVIOMETER ?? [];
  const temperatureMax = response.measured.parameters.TEMPERATURE_MAX ?? [];
  const temperatureMin = response.measured.parameters.TEMPERATURE_MIN ?? [];

  const temperatureFormatted = formatWeatherData(
    temperature,
    `avgmeasuredTemperatura${name}`,
  );
  const pluviometerFormatted = formatWeatherData(
    pluviometer,
    `avgmeasuredPluviometer${name}`,
    true,
  );
  const temperatureMaxFormatted = formatWeatherData(
    temperatureMax,
    `maxmeasuredTemperatura${name}`,
  );
  const temperatureMinFormatted = formatWeatherData(
    temperatureMin,
    `minmeasuredTemperatura${name}`,
  );

  // return [...temperatureFormatted, ...pluviometerFormatted];
  return {
    temperature: temperatureFormatted,
    pluviometer: pluviometerFormatted,
    temperatureMax: temperatureMaxFormatted,
    temperatureMin: temperatureMinFormatted,
  };
}

async function fetchCode(companyId, plantationId) {
  const xhr = await Api.getTreeLocation(companyId, plantationId);
  const response = xhr.response.length > 0 ? JSON.parse(xhr.response) : [];
  return response?.[0]?.children?.[0]?.code;
}

function getRandomHexColor() {
  const letters = '0123456789ABCDEF';
  let color = '#FF';
  for (let i = 0; i < 4; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default {
  namespaced: true,
  state: {
    initDate: null,
    endDate: null,
    observations: [],
    tasks: [],
    timelineIndexes: [],
    weatherData: {
      temperature: [],
      pluviometer: [],
    },
  },
  actions: {
    // initReportGisData({ commit }) {
    //   commit('SET_INIT_DATE', null);
    //   commit('SET_END_DATE', null);
    // },
    setInitDate({ commit }, newDate) {
      commit('SET_INIT_DATE', newDate);
    },
    setEndDate({ commit }, newDate) {
      commit('SET_END_DATE', newDate);
    },
    async fetchObservations(
      { state, commit },
      {
        companyId, plantationId, subcategory, position = '',
      },
    ) {
      try {
        const xhr = await Api.getObservationsFiltered(
          companyId,
          plantationId,
          subcategory,
          moment(state.initDate).format('YYYY-MM-DD'),
          moment(state.endDate).format('YYYY-MM-DD'),
        );

        const color = getRandomHexColor();

        const observations = JSON.parse(xhr.response).results.map((item) => {
          item.position = position;
          item.color = color;
          return item;
        });

        commit('SET_OBSERVATIONS', observations);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(helpers.getError(error));
      }
    },
    removeObservations({ commit }, subcategory) {
      commit('REMOVE_OBSERVATION', subcategory);
    },
    removeTasks({ commit }, activity) {
      commit('REMOVE_TASK', activity);
    },
    resetObservations({ commit }) {
      commit('RESET_OBSERVATIONS');
    },
    resetTasks({ commit }) {
      commit('RESET_TASKS');
    },
    resetWeatherData({ commit }) {
      commit('RESET_WEATHER_DATA');
    },
    resetIndexData({ commit }) {
      commit('RESET_INDEX_DATA');
    },
    async fetchTimelineIndexes({ commit }, data) {

      try {
        const dataIndexes = await fetchIndexes(data).then((indexes) => indexes.map((item) => ({
          date: item.date,
          index: item.index,
          mean:
              indexLimits[item.index?.toUpperCase()] == null
              || item.mean == null
                ? item.mean
                : Math.max(
                  Math.min(
                    item.mean,
                    indexLimits[item.index?.toUpperCase()].max,
                  ),
                  indexLimits[item.index?.toUpperCase()].min,
                ),
        })));

        const firstDate = dataIndexes?.[0]?.date;
        const lastDate = dataIndexes?.[dataIndexes.length - 1]?.date;

        if (lastDate !== data.endDate) {

          dataIndexes.push({
            date: data.endDate,
            index: data.currentIndex.toLowerCase(),
            mean: null,
          });
        }

        if (firstDate !== data.initDate) {

          dataIndexes.push({
            date: data.initDate,
            index: data.currentIndex.toLowerCase(),
            mean: null,
          });
        }

        commit('SET_TIMELINE_INDEXES', dataIndexes);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(helpers.getError(error));
      }
    },
    async fetchTimelineIndexesToCompare({ commit }, { left, right }) {
      try {
        const leftIndexes = await fetchIndexes(left).then((indexes) => indexes.map((item) => ({
          date: item.date,
          index: item.index,
          mean_left:
              indexLimits[item.index?.toUpperCase()] == null
              || item.mean == null
                ? item.mean
                : Math.max(
                  Math.min(
                    item.mean,
                    indexLimits[item.index?.toUpperCase()].max,
                  ),
                  indexLimits[item.index?.toUpperCase()].min,
                ),
        })));

        const firstDateLeft = leftIndexes?.[0]?.date;
        const lastDateLeft = leftIndexes?.[leftIndexes.length - 1]?.date;

        if (lastDateLeft !== left.endDate) {
          leftIndexes.push({
            date: left.endDate,
            index: left.currentIndex.toLowerCase(),
            mean_left: null,
          });
        }

        if (firstDateLeft !== left.initDate) {
          leftIndexes.push({
            date: left.initDate,
            index: left.currentIndex.toLowerCase(),
            mean_left: null,
          });
        }

        const rightIndexes = await fetchIndexes(right).then((indexes) => indexes.map((item) => ({
          date: item.date,
          index: item.index,
          mean_right:
              indexLimits[item.index?.toUpperCase()] == null
              || item.mean == null
                ? item.mean
                : Math.max(
                  Math.min(
                    item.mean,
                    indexLimits[item.index?.toUpperCase()].max,
                  ),
                  indexLimits[item.index?.toUpperCase()].min,
                ),
        })));

        const firstDateRight = rightIndexes?.[0]?.date;
        const lastDateRight = rightIndexes?.[rightIndexes.length - 1]?.date;

        if (lastDateRight !== right.endDate) {
          rightIndexes.push({
            date: right.endDate,
            index: right.currentIndex.toLowerCase(),
            mean_right: null,
          });
        }

        if (firstDateRight !== right.initDate) {
          rightIndexes.push({
            date: right.initDate,
            index: right.currentIndex.toLowerCase(),
            mean_right: null,
          });
        }

        commit('SET_TIMELINE_INDEXES', [...leftIndexes, ...rightIndexes]);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(helpers.getError(error));
      }
    },
    async fetchWeatherData(
      { commit },
      {
        companyId, plantationId, initDate, endDate, name = '',
      },
    ) {
      commit('RESET_WEATHER_DATA');

      const code = await fetchCode(companyId, plantationId);
      if (code != null) {
        const formattedData = await fetchWeather({
          companyId,
          plantationId,
          initDate,
          endDate,
          name,
          code,
        });

        commit('SET_WEATHER_DATA_TEMPERATURE', formattedData.temperature);
        commit('SET_WEATHER_DATA_PLUVIOMETER', formattedData.pluviometer);
        commit('SET_WEATHER_DATA_TEMPERATURE', formattedData.temperatureMax);
        commit('SET_WEATHER_DATA_TEMPERATURE', formattedData.temperatureMin);
      }
    },
    async fetchTasks(
      { commit, state },
      {
        companyId, plantation, activity, position = '',
      },
    ) {
      try {
        const xhr = await Api.getTasksForCompanyFiltered(
          companyId,
          plantation,
          activity,
          moment(state.initDate).format('YYYY-MM-DD'),
          moment(state.endDate).format('YYYY-MM-DD'),
        );
        const result = JSON.parse(xhr.response).data;

        const color = tasksColors[activity];

        const tasks = result.map((item) => {
          item.position = position;
          item.color = color;
          return item;
        });

        commit('SET_TASKS', tasks);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(helpers.getError(error));
      }
    },
    initDataReportGis({ commit }) {
      commit('INIT_DATA');
    },
  },
  mutations: {
    SET_INIT_DATE(state, newDate) {
      state.initDate = newDate;
    },
    SET_END_DATE(state, newDate) {
      state.endDate = newDate;
    },
    SET_OBSERVATIONS(state, newObservations) {
      state.observations.push(...newObservations);
    },
    SET_TASKS(state, newTasks) {
      state.tasks.push(...newTasks);
    },
    REMOVE_OBSERVATION(state, subcategory) {
      state.observations = state.observations.filter(
        (item) => item.subcategory !== subcategory,
      );
    },
    REMOVE_TASK(state, activity) {
      state.tasks = state.tasks.filter((item) => item.activity !== activity);
    },
    RESET_OBSERVATIONS(state) {
      state.observations = [];
    },
    RESET_TASKS(state) {
      state.tasks = [];
    },
    RESET_INDEX_DATA(state) {
      state.timelineIndexes = [];
    },
    SET_TIMELINE_INDEXES(state, indexes) {
      state.timelineIndexes = indexes;
    },
    SET_WEATHER_DATA_TEMPERATURE(state, data) {
      state.weatherData.temperature.push(...data);
    },
    SET_WEATHER_DATA_PLUVIOMETER(state, data) {
      state.weatherData.pluviometer.push(...data);
    },
    RESET_WEATHER_DATA(state) {
      state.weatherData = {
        temperature: [],
        pluviometer: [],
      };
    },
    INIT_DATA(state) {
      state.initDate = null;
      state.endDate = null;
      state.observations = [];
      state.tasks = [];
      state.timelineIndexes = [];
      state.weatherData = {
        temperature: [],
        pluviometer: [],
      };
    },
  },
};

<template>
  <DxPopup
    title=""
    height="auto"
    :width="500"
    :visible="visible"
    :close-on-outside-click="true"
    :on-hidden="close"
    :drag-enabled="false"
  >
    <div>
      <div style="text-align: center; display: flex; justify-content: center">
        <div style="max-width: 380px; padding: 1rem 0; font-weight: 500">
          {{ getInfo }}
        </div>
      </div>
      <div style="text-align: center; display: flex; justify-content: center">
        <div style="padding: 2rem 0">
          <DxDateBox
            v-model="date"
            :display-format="'dd/MM/yyyy'"
            picker-type="calendar"
            width="300px"
            type="date"
            :accept-custom-value="false"
            :open-on-field-click="true"
          />
        </div>
      </div>
      <f7-row>
        <f7-col>
          <f7-button
            class="dx-btn-cancel margin-left-block btn-width-150 outlined-gray margin-top-block margin-bottom-block"
            raised
            type="default"
            styling-mode="contained"
            @click="close()"
          >
            {{ $t('Button_cancel_text') }}
          </f7-button>
        </f7-col>
        <f7-col class="justify-content-end">
          <f7-button
            class="dx-btn-cancel margin-right-block btn-width-150 margin-top-block margin-bottom-block standard-blue-btn"
            raised
            type="success"
            styling-mode="contained"
            @click="copy()"
          >
            {{ $t('continue') }}
          </f7-button>
        </f7-col>
      </f7-row>
    </div>
  </DxPopup>
</template>
<script>
import { DxPopup } from 'devextreme-vue/popup';
import { DxDateBox } from 'devextreme-vue/date-box';
import moment from 'moment';

export default {
  name: 'ModalCopyReferencePlan',
  components: { DxPopup, DxDateBox },
  props: {
    id: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'reference',
    },
  },
  data() {
    return {
      date: new Date(),
    };
  },
  computed: {
    getInfo() {
      return this.$t(`modalCopyReferencePlan_info_${this.type}`);
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    copy() {
      if(this.type === 'nutrition') {
        this.$fa.logEvent('Análisis - Planes- Nutrición - Copiar', {
        date: this.date
      });
      } else {
        this.$fa.logEvent('Análisis - Planes- Tratamientos - Copiar', {
        date: this.date
      });
      } 

      this.$emit('copy', moment(this.date).format('YYYY-MM-DD'));
    },
  },
};
</script>

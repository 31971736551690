<template>
  <div class="margin-top-half">
    <div class="content-devx">
      <f7-block>
        <div>
          <f7-row>
            <f7-col>{{ $t("DxClients_client") }}</f7-col>
            <f7-col>{{ $t("DxClients_telefono") }}</f7-col>
            <f7-col>{{ $t("DxClients_numero_movil") }}</f7-col>
            <f7-col>{{ $t("DxClients_provincia") }}</f7-col>
            <f7-col>{{ $t("DxClients_localidad") }}</f7-col>
            <f7-col>{{ $t("DxClients_postal_code") }}</f7-col>
          </f7-row>
        </div>
        <div class="bold">
          <f7-row>
            <f7-col>{{ actualUser.name }}</f7-col>
            <f7-col>{{ actualUser.phone }}</f7-col>
            <f7-col>{{ actualUser.mobile_phone }}</f7-col>
            <f7-col>{{ actualUser.province }}</f7-col>
            <f7-col>{{ actualUser.city }}</f7-col>
            <f7-col>{{ actualUser.postal_code }}</f7-col>
          </f7-row>
        </div>
      </f7-block>
    </div>
    <div class="content-devx">
      <f7-block-title class="title-top">
        <span class="uppercase-text">{{ `${$t("DxViewClient_detail_Ventas")}` }}</span>
      </f7-block-title>
      <f7-block>
        <DxDataGrid
          id="DataSales"
          :data-source="allSales"
          :remote-operations="true"
          :column-min-width="70"
          :column-auto-width="true"
          :allow-column-resizing="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
        >
          <DxSelection mode="single" />
          <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="DxViewClient"
          />
          <DxHeaderFilter :visible="true" />
          <DxExport
            :enabled="false"
          />
          <DxPaging :page-size="gridSettings.pageSize" />
          <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="pageSizes"
            :show-info="true"
          />
          <DxSearchPanel
            :visible="true"
            :width="200"
            :placeholder="$t('Search_placeholder')"
          />
          <DxColumn
            data-field="sale_date"
            :width="90"
            :calculate-display-value="formatingDate"
            :caption="$t('DxSales_sale_date')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="sale_point"
            :caption="$t('DxSales_sale_point')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="deal"
            :caption="$t('DxSales_company')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="product_code"
            :caption="$t('DxSales_product_code')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="product_name"
            :caption="$t('DxSales_product')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="quantity"
            :caption="$t('DxSales_quantity')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="specie"
            :caption="$t('DxSales_species')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="status"
            :caption="$t('DxSales_status')"
            :allow-header-filtering="false"
            alignment="left"
          />
        </DxDataGrid>
      </f7-block>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import settings from '../../../js/commonSettings';

export default {
  name: 'DxViewClient',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
  },
  data() {
    return {
      loaded: false,
      VarietyFormated: [],
      pageSizes: [5, 10, 20],
      isEdit: false,
      popupVisible: false,
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('Explotaciones', ['actualUser']),
    ...mapState('Sales', ['allSales']),
    isEditing() {
      return this.isEdit === true
        ? this.$t('DxClient_edit_Client')
        : this.$t('DxClient_register_Client');
    },
  },
  async beforeMount() {
    this.loaded = true;
    try {
      this.$fa.logEvent('Clientes - Ventas');
      await this.getAllSales();
    } catch (error) {
      const message = this.$helpers.getFilteredErrorMessage(error);
      if (message.includes('default_') && !message.includes('404')) {
        this.$notifyDX(
          {
            message: message.split('_').pop(),
            width: 550,
          },
          'error',
          3000,
        );
      } else if (!message.includes('404')) {
        this.$notifyDX(
          {
            message: this.$t(message),
            width: 550,
          },
          'error',
          3000,
        );
      }
    }
  },
  methods: {
    formatingDate(e) {
      return moment(e.sale_date).format('DD/MM/YYYY');
    },
    ...mapActions('Sales', ['getAllSales']),
  },
};
</script>
<style scoped>
.bold {
  font-weight: bold;
}

.uppercase-text {
  text-transform: uppercase;
}

.content-devx {
  position: relative;
}

@media only screen and (min-width: 270px){
  .title-top {
    position: absolute;
    left: 5px;
    top: 0;
    z-index: 9999;
  }
}
</style>

<template>
  <div>
    <div
      class="content-devx-margin-block"
    >
      <div class="margin-bottom">
        <f7-row>
          <f7-col width="80">
            <f7-block-title>
              {{ $t('Searcher.observations.multimedia_content_title') }}
            </f7-block-title>
          </f7-col>
          <f7-col
            width="20"
            class="text-align-right"
          >
            <AttachFilePopup v-if="!readOnly" />
          </f7-col>
        </f7-row>
      </div>
      <DxDataGrid
        id="MultimediaData-datagrid"
        :data-source="multimedia"
        :remote-operations="true"
        :column-min-width="70"
        :column-auto-width="true"
        :allow-column-resizing="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
      >
        <DxSelection
          mode="single"
        />
        <!-- <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="MultimediaData"
        /> -->
        <DxPaging :page-size="gridSettings.pageSize" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
        />
        <DxColumn
          data-field=""
          :caption="$t('thumbnail')"
          :allow-header-filtering="false"
          alignment="left"
          cell-template="thumbnail"
        />
        <DxColumn
          data-field="description"
          :caption="$t('Searcher.observations.description')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="extension"
          :caption="$t('Searcher.observations.file')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field=""
          alignment="center"
          cell-template="download"
          :width="50"
        />
        <DxColumn
          data-field=""
          alignment="center"
          cell-template="delete"
          :width="50"
        />
        <template #thumbnail="{ data }">
          <div>
            <img
              v-if="getThumbnail(data).length > 0"
              :src="getThumbnail(data)"
              :alt="data.description"
              class="img-thumbnail"
            >
          </div>
        </template>
        <template #download="{ data }">
          <f7-button
            class="dx-btn-pdf"
            raised
            type="default"
            styling-mode="outlined"
            @click="openFile( data.data)"
          >
            <f7-icon
              md="material:file_download"
            />
          </f7-button>
        </template>
        <template #delete="{ data }">
          <f7-button
            class="dx-btn-pdf"
            raised
            type="default"
            styling-mode="outlined"
            :disabled="readOnly"
            @click="removeFile(data.data)"
          >
            <f7-icon
              md="material:clear"
            />
          </f7-button>
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';

import { Device } from 'framework7/framework7-lite.esm.bundle';
import AttachFilePopup from './attachFilePopup/index.vue';
import settings from '../../../../../js/commonSettings';

export default {
  name: 'MultimediaData',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxStateStoring,
    DxPager,
    DxSelection,
    AttachFilePopup,
  },
  props: {
    multimedia: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
  },
  methods: {
    async openFile(urlFile) {
      try {

        // Realiza la solicitud para obtener la imagen
        const response = await fetch(urlFile.attachment);
        if (!response.ok) {
          throw new Error('Error al descargar la imagen');
        }

        if (Device.desktop) {
        const linkSource = urlFile.attachment;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = urlFile.description + urlFile.extension;
        downloadLink.click();
      } else {
        this.downloadForMobile(urlFile);
      }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t('FileNotFound'),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },

    checkForReadWritePermissions() {
      const { permissions } = cordova.plugins;
      const list = [
        permissions.READ_MEDIA_IMAGES,
      ];
      permissions.hasPermission(list, success, null);

      function error() {
        this.$notifyDX(
          {
            message: this.$t('PermissionsReadWrite'),
            width: 550,
          },
          'error',
          3000,
        );
      }

      function success(status) {
        if (!status.hasPermission) {
          permissions.requestPermissions(
            list,
            (status) => {
              if (!status.hasPermission) error();
            },
            error,
          );
        }
      }
    },

    downloadForMobile(urlFile) {
      this.checkForReadWritePermissions();
      const path = `${cordova.file.externalRootDirectory}Download/`;
      const filename = urlFile.description + urlFile.extension;
      const uri = encodeURI(urlFile.attachment);
      const fileTransfer = new FileTransfer();

      fileTransfer.download(
        uri,
        path + filename,
        () => {
          this.$notifyDX(
            {
              message: this.$t('FileDownloadedInDownloads'),
              width: 550,
            },
            'info',
            3000,
          );
        },
        (error) => {
          if (error.code === 1) {
            this.$notifyDX(
              {
                message: this.$t('PermissionsReadWriteCode1'),
                width: 550,
              },
              'error',
              3000,
            );
          } else {
            this.$notifyDX(
              {
                message: `Error:${error.code}`,
                width: 550,
              },
              'error',
              3000,
            );
          }
        },
        false,
        {
          headers: {
            Authorization: 'Basic dGVzdHVzZXJuYW1lOnRlc3RwYXNzd29yZA==',
          },
        },
      );
    },

    getThumbnail(data) {
      const imagesExtension = ['.png', '.jpg', '.jpeg'];

      if (imagesExtension.includes(data.data.extension.toLowerCase())) {
        return data.data.attachment;
      }

      return '';
    },

    ...mapActions('SearcherObservations', ['removeFile']),
  },
};
</script>
<style lang="scss" scoped>
@import './MultimediaData.styles.scss';
</style>

<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="$t('AnalysisGis.title')" />
    <f7-block>
      <div class="justify-content-end padding-top-half padding-bottom margin-right-half">
        <ContextMenu :items="items" />
      </div>

      <f7-row>
        <f7-col width="50">
          <f7-block v-if="!loadedGIS || currentIndexes[`${mainMap}Left`] === ''">
            <div class="loadingFalse">
              <div class="preloader" />
            </div>
          </f7-block>
          <f7-block v-else>
            <GISWMSMap
              :zoom="zoom"
              :name-map="`${mainMap}Left`"
              :layer="layerLeft"
              :index="currentIndexes[`${mainMap}Left`]"
              :height="'450px'"
              :center="LeftComparatorCoordinates"
              :user-location="geolocation"
              :companyId="LeftComparatorCompanyId"
            />
          </f7-block>
        </f7-col>
        <f7-col width="50">
          <f7-block v-if="!loadedGIS || currentIndexes[`${mainMap}Right`] === ''">
            <div class="loadingFalse">
              <div class="preloader" />
            </div>
          </f7-block>
          <f7-block v-else>
            <GISWMSMap
              :zoom="zoom"
              :name-map="`${mainMap}Right`"
              :layer="layerRight"
              :index="currentIndexes[`${mainMap}Right`]"
              :height="'450px'"
              :center="RightComparatorCoordinates"
              :user-location="geolocation"
              :companyId="RightComparatorCompanyId"
            />
          </f7-block>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col
          v-if="loadedGIS"
          width="50"
        >
          <GISLayerOptionsComparator
            :key-id="`${mainMap}Left`"
            :default-index="'NDVI'"
            :dates-list="datesListLeft"
            :is-comparator="true"
            side="left"
            @date-changed="handleDateChange"
          />
          <CloudCoverage
            :cloudsData="cloudsDataComparatorLeft"
          />
          <div class="padding">
            <HistogramByClass
              v-if="histogramLeft.histogram != null && loadedHistogramLeft && currentIndexes[`${mainMap}Left`] !== 'TCI'"
              :histogram="histogramLeft.histogram"
              :showing-date="histogramLeft.iso_date"
              :index="currentIndexes[`${mainMap}Left`]"
              :max="histogramLeft.max"
              :min="histogramLeft.min"
              :show-area="true"
            />
          </div>
        </f7-col>
        <f7-col
          v-if="loadedGIS"
          width="50"
        >
          <GISLayerOptionsComparator
            :key-id="`${mainMap}Right`"
            :default-index="'NDVI'"
            :dates-list="datesListRight"
            :is-comparator="true"
            side="right"
            @date-changed="handleDateChange"
          />
          <CloudCoverage
            :cloudsData="cloudsDataComparatorRight"
          />
          <div class="padding">
            <HistogramByClass
              v-if="histogramRight.histogram != null && loadedHistogramRight && currentIndexes[`${mainMap}Right`] !== 'TCI'"
              :histogram="histogramRight.histogram"
              :showing-date="histogramRight.iso_date"
              :index="currentIndexes[`${mainMap}Right`]"
              :max="histogramRight.max"
              :min="histogramRight.min"
              :show-area="true"
            />
          </div>
        </f7-col>
      </f7-row>

      <f7-row v-if="loadedGIS">
        <f7-col>
          <f7-button
            class="dx-btn-cancel margin-right"
            raised
            type="default"
            text-color="grey"
            styling-mode="outlined"
            name="ButtonCancel"
            @click="goToGisGrid()"
          >
            {{ $t('Button_go_to_back') }}
          </f7-button>
        </f7-col>
        <f7-col />
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import ContextMenu from '../../components/contextMenu/index.vue';
import navbar from '../../components/NavBar.vue';
import Api from '../../services/Api';
import GISWMSMap from '../../components/Gis/GISWMSMap/index.vue';
import GISLayerOptionsComparator from '../../components/Gis/LayerOptionsComparator/index.vue';
import HistogramByClass from '../../components/searcher/gis/histogramByClass/index.vue';
import EventBus from '../../js/event-bus';
import CloudCoverage from '../../components/Gis/CloudCoverage/CloudCoverage.vue';

export default {
  name: 'GisComparatorPage',
  components: {
    navbar,
    GISWMSMap,
    GISLayerOptionsComparator,
    ContextMenu,
    HistogramByClass,
    CloudCoverage,
  },
  data() {
    return {
      items: [
        {
          icon: 'download',
          text: this.$t('ContextMenu.Download'),
          onItemClick: this.viewReport,
        },
      ],
      loadedGIS: false,
      zoom: 17,
      mainMap: 'gis',
      datesListLeft: {},
      datesListRight: {},
      layerLeft: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      layerRight: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      ws: Api.getGeoServerWorkspace(),
      geolocation: true,
      histogramLeft: {},
      histogramRight: {},
      loadedHistogram: false,
      loadedHistogramLeft: false,
      loadedHistogramRight: false,
      actualDateLeft: null,
      actualIndexLeft: 'NDVI',
      actualDateRight: null,
      actualIndexRight: 'NDVI',
      cloudsDataComparatorLeft: null,
      cloudsDataComparatorRight: null,
    };
  },
  computed: {
    ...mapState('Plantation', ['sectorIdLeftComparator', 'sectorIdRightComparator', 'LeftComparatorCoordinates', 'RightComparatorCoordinates', 'LeftComparatorCompanyId', 'RightComparatorCompanyId']),
    ...mapState('Gis', ['currentIndexes', 'datesList', 'currentGisComparatorLeft', 'currentGisComparatorRight']),
  },
  methods: {
    resetConfig(key) {
      this.setCurrentIndexes({
        key,
        newCurrentIndex: 'NDVI',
      });
      this.setCurrentInitIntervalDates({
        key,
        newCurrentInitIntervalDate: '',
      });
      this.setCurrentEndIntervalDates({
        key,
        newCurrentEndIntervalDate: '',
      });
    },
    async beforein() {
      // Borramos las selecciones de tares y observaciones antes de entrar a la vista reportstep2
      this.setSelectedTasks([]);
      this.setSelectedObservations([]);
      this.$f7.preloader.show();
      try {
        this.layerLeft.options.layers = `${this.ws}:${this.sectorIdLeftComparator}`;
        this.layerRight.options.layers = `${this.ws}:${this.sectorIdRightComparator}`;

        await this.fetchDatesLayer({ layer: this.layerLeft.options.layers.split(':')[1], companyId: this.LeftComparatorCompanyId });
        this.datesListLeft = this.datesList;

        const leftDatesLen = this.datesList?.avaiable_dates?.length || 0;
        const lastDateLeft = leftDatesLen > 0 ? moment(this.datesList.avaiable_dates[leftDatesLen - 1]).format('YYYY-MM-DD') : null;
        this.actualDateLeft = lastDateLeft;
         // seteamos la fecha del comparador de la izquierda al entrar con la fecha que tiene por defecto
        this.setCurrentInitIntervalDates({
          key: 'gisLeft',
          newCurrentInitIntervalDate: this.actualDateLeft,
        });

        await this.fetchDatesLayer({ layer: this.layerRight.options.layers.split(':')[1], companyId: this.RightComparatorCompanyId });
        this.datesListRight = this.datesList;


        const rightDatesLen = this.datesList?.avaiable_dates?.length || 0;
        const lastDateRight = rightDatesLen > 0 ? moment(this.datesList.avaiable_dates[rightDatesLen - 1]).format('YYYY-MM-DD') : null;
        this.actualDateRight = lastDateRight;
        // seteamos la fecha del comparador de la derecha al entrar con la fecha que tiene por defecto
        this.setCurrentInitIntervalDates({
          key: 'gisRight',
          newCurrentInitIntervalDate: this.actualDateRight,
        });

        this.actualIndexLeft = 'NDVI';
        this.actualIndexRight = 'NDVI';
        // this.actualDateLeft = lastDate;
        // this.actualDateRight = lastDate;

        await this.fetchAllHistogram(
          {
            index: this.currentIndexes?.gisLeft ?? 'NDVI',
            date: this.actualDateLeft,
            sector: this.sectorIdLeftComparator,
            company: this.LeftComparatorCompanyId,
          },
          {
            index: this.currentIndexes?.gisRight ?? 'NDVI',
            date: this.actualDateRight,
            sector: this.sectorIdRightComparator,
            company: this.RightComparatorCompanyId,
          },
        );

        EventBus.$on(`indexLayer${this.mainMap}Right`, (value) => {
          this.actualIndexRight = value;

          this.fetchAllHistogram(
            null,
            {
              index: this.actualIndexRight ?? 'NDVI',
              date: this.actualDateRight,
              sector: this.sectorIdRightComparator,
              company: this.RightComparatorCompanyId,
            },
          );
        });

        EventBus.$on(`indexLayer${this.mainMap}Left`, (value) => {
          this.actualIndexLeft = value;

          this.fetchAllHistogram(
            {
              index: this.actualIndexLeft ?? 'NDVI',
              date: this.actualDateLeft,
              sector: this.sectorIdLeftComparator,
              company: this.LeftComparatorCompanyId,
            },
            null,
          );
        });
        EventBus.$on(`updateDateLayer${this.mainMap}Right`, (value) => {
          this.actualDateRight = value;

          this.fetchAllHistogram(
            null,
            {
              index: this.actualIndexRight ?? 'NDVI',
              date: this.actualDateRight,
              sector: this.sectorIdRightComparator,
              company: this.RightComparatorCompanyId,
            },
          );
        });
        EventBus.$on(`updateDateLayer${this.mainMap}Left`, (value) => {
          this.actualDateLeft = value;

          this.fetchAllHistogram(
            {
              index: this.actualIndexLeft ?? 'NDVI',
              date: this.actualDateLeft,
              sector: this.sectorIdLeftComparator,
              company: this.LeftComparatorCompanyId,
            },
            null,
          );
        });
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(this.$helpers.getFilteredErrorMessage(error.message)),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
        this.loadedGIS = true;
        await this.fetchCloudData(this.LeftComparatorCompanyId, this.RightComparatorCompanyId);
      }
    },
    async fetchHistogram(location, date, company) {
      const xhr = await Api.getGeoServerTemperatureHistogram(
        location,
        date,
        company,
      );

      return xhr.json().then((res) => res.data);
    },
    async getFormattedHistogram(sectorId, date, company, currentIndex) {
      const histogram = await this.fetchHistogram(
        sectorId,
        moment(date).format('YYYY-MM-DD'),
        company,
      );

      if (histogram.length === 0) return {};

      const histogramData = histogram[0].values.find(
        (item) => item.index === currentIndex.toLowerCase(),
      );

      return {
        histogram: histogramData?.histogram || [],
        iso_date: histogram[0].date || null,
        current_index: currentIndex,
        max: histogramData?.max,
        mean: histogramData?.mean,
        min: histogramData?.min,
        stddev: histogramData?.stddev
      };
    },
    async fetchAllHistogram(left, right) {
      if (left != null && right != null) {
        // this.loadedHistogram = false;
      } else {
        this.loadedHistogramLeft = left == null;
        this.loadedHistogramRight = right == null;
      }

      if (left != null && left.date != null) {
        this.histogramLeft = await this.getFormattedHistogram(
          left.sector,
          left.date,
          left.company,
          left.index,
        );
      }

      if (right != null && right.date != null) {
        this.histogramRight = await this.getFormattedHistogram(
          right.sector,
          right.date,
          right.company,
          right.index,
        );
      }

      setTimeout(() => {
        this.loadedHistogram = true;
        this.loadedHistogramLeft = true;
        this.loadedHistogramRight = true;
      }, 100);
    },
    goToGisGrid() {
      this.$f7.views.main.router.navigate('/gisGrid/', { reloadCurrent: true });
    },
    viewReport() {
      this.initDataReportGis();
      this.$f7.views.main.router.navigate('/analysisReportGis/', { reloadCurrent: true });
    },
    async fetchCloudData(leftCompanyId, rightCompanyId) {
      const dateCloud = this.getDateForHistogram();
      // Datos de la nube del comparador izquierdo
      try {
        const resultLeft = await Api.getGeoServerClouds(this.sectorIdLeftComparator, this.actualDateLeft, leftCompanyId);
        const formattedCloudLeft = JSON.parse(resultLeft.response);
        this.cloudsDataComparatorLeft = Number(formattedCloudLeft.clouds);
        this.setCurrentGisComparatorLeft(this.cloudsDataComparatorLeft);
      } catch (error) {
        console.error('Error fetching cloud data for left comparator:', error);
        this.setCurrentGisComparatorLeft(0);
        this.cloudsDataComparatorLeft = 0;
      }

      // Datos de la nube del comparador derecho
      try {
        const resultRight = await Api.getGeoServerClouds(this.sectorIdRightComparator, dateCloud, rightCompanyId);
        const formattedCloudRight = JSON.parse(resultRight.response);
        this.cloudsDataComparatorRight = Number(formattedCloudRight.clouds);
        this.setCurrentGisComparatorRight(this.cloudsDataComparatorRight);
      } catch (error) {
        console.error('Error fetching cloud data for right comparator:', error);
        this.setCurrentGisComparatorRight(0);
        this.cloudsDataComparatorRight = 0;
      }
    },
    getDateForHistogram() {
      const availableDates = this.datesList.avaiable_dates || this.datesList;
      return moment(availableDates[availableDates.length - 1]).format('YYYY-MM-DD');
    },
    handleCloudData(cloudData, side) {
      if (cloudData) {
        const cloudsDataComparator = Number(cloudData);
        if (side === 'left') {
            this.cloudsDataComparatorLeft = cloudsDataComparator;
            this.setCurrentGisComparatorLeft(this.cloudsDataComparatorLeft);
        } else if (side === 'right') {
            this.cloudsDataComparatorRight = cloudsDataComparator;
            this.setCurrentGisComparatorRight(this.cloudsDataComparatorRight);
        }
      } else {
        if (side === 'left') {
            this.cloudsDataComparatorLeft = 0;
            this.setCurrentGisComparatorLeft(0);
        } else if (side === 'right') {
            this.cloudsDataComparatorRight = 0;
            this.setCurrentGisComparatorRight(0);
        }
      }
    },
    async handleDateChange(data) {
      const { date, side } = data;
      const companyID = side === 'left' ? this.LeftComparatorCompanyId : this.RightComparatorCompanyId;
      const sectorId = side === 'left' ? this.sectorIdLeftComparator : this.sectorIdRightComparator;

      try {
        let formattedCloud = null;
        const dateInit = moment(date, 'YYYY/MM/DD').format('YYYY-MM-DD');
        const result = await Api.getGeoServerClouds(sectorId, dateInit, companyID);
        formattedCloud = JSON.parse(result.response);
        const cloudData = formattedCloud.clouds;
        this.handleCloudData(cloudData, side);
      } catch (error) {
        this.handleCloudData(null, side);
        console.error('Error fetching cloud data:', error);
      }
    },
    ...mapActions('Gis', ['setCurrentIndexes', 'setCurrentInitIntervalDates', 'setCurrentEndIntervalDates', 'fetchDatesLayer', 'setCurrentGisComparatorLeft', 'setCurrentGisComparatorRight']),
    ...mapActions('ReportGis', ['initDataReportGis']),
    ...mapActions('TimeDimension', ['setSelectedTasks', 'setSelectedObservations']),
  },
};
</script>

<style>

</style>

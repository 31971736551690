<template>
  <div
    class="content-devx margin-top-half"
  >
    <DxDataGrid
      id="Plans-datagrid"
      :data-source="customStore"
      :remote-operations="true"
      :column-min-width="70"
      :column-auto-width="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="analyticsPlansDataGrid"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="gridSettings.pageSize" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        info-text="{2} items"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        v-if="hasATC"
        data-field="owner_fullname"
        :caption="$t('DxAtc_atc')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="description"
        :caption="$t('Plans.planName')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="company_name"
        :caption="$t('client')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="species"
        :caption="$t('Plans.crop')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="variety"
        :caption="$t('Plans.variety')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="province"
        :caption="$t('Plans.province')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="cost"
        :caption="$t('Plans.cost')"
        :allow-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="period"
        :caption="$t('Plans.timeFrame')"
        :allow-header-filtering="false"
        alignment="left"
      />

      <DxColumn
        data-field="init_date"
        data-type="date"
        :caption="$t('searchEngine_init_date')"
        :allow-header-filtering="false"
        :customize-text="dataFormatted"
      />

      <DxColumn
        data-field="end_date"
        data-type="date"
        :caption="$t('searchEngine_end_date')"
        :allow-header-filtering="false"
        :customize-text="dataFormatted"
      />

      <DxColumn
        type="buttons"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="viewPlanDetail"
        />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import moment from 'moment';
import settings from '../../../js/commonSettings';

export default {
  name: 'Plans',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
  },
  props: {
    customStore: {
      type: Object,
      default: () => [],
    },
    plans: {
      type: Array,
      default: () => [],
    },
    currentTypePlan: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    hasATC() {
      return this.user?.user?.contact?.admin ?? false;
    },
    ...mapState('authentication', ['user']),
  },
  methods: {
    dataFormatted(cellInfo) {
      return moment(cellInfo.valueText, 'DD/MM/YYYY').format('DD-MM-YYYY');
    },
    async viewPlanDetail(e) {
      if(this.currentTypePlan === 'fertilization'){
        this.$fa.logEvent('Análisis - Planes- Nutrición', {
        id: e.row.data.id
      });
      }
      else {
        this.$fa.logEvent('Análisis - Planes- Tratamientos', {
        id: e.row.data.id
      });
      }
      
      this.setCurrentFertilizationId(e.row.data.id);
      this.setCurrentTreatmentId(e.row.data.id);

      this.setCurrentPlanId(e.row.data.id);
      this.setOldPlantation(e.row.data);
      this.setCurrentTypePlan(this.currentTypePlan);

      this.setIsNew(false);

      this.$f7.views.main.router.navigate('/viewPlanDetail/', { reloadCurrent: true });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Plans');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Plans.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Plans', ['setCurrentPlanId', 'setCurrentTypePlan']),
    ...mapActions('Plantation', ['setOldPlantation']),
    ...mapActions('fertilizationStore', ['setCurrentFertilizationId']),
    ...mapActions('treatmentStepper', ['setCurrentTreatmentId']),
    ...mapActions('nutritionStepper', ['setIsNew']),

  },
};
</script>
<style lang="scss" scoped>
@import './Plans.styles.scss';
</style>

<template>
  <div class="list-by-group-reference-plans content-devx content-separatos">
    <DxDataGrid
      :data-source="referencePlans"
      :show-borders="true"
      :row-alternation-enabled="true"
      :show-row-lines="true"
      :show-column-lines="true"
    >
      <DxSearchPanel
        :visible="true"
        :width="240"
        :placeholder="$t('Search_placeholder')"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :visible="true"
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 25]"
        :show-info="true"
        info-text="{2} items"
      />

      <DxColumn
        v-for="table in columnsSimple"
        :key="table.dataField"
        :data-field="table.dataField"
        :caption="table.caption"
        :width="table.width"
        :fixed="table.fixed"
        :fixed-position="table.fixedPosition"
        :allow-sorting="true"
      />
      <DxColumn
        caption="UF EFICIENTES"
        alignment="center"
      >
        <DxColumn
          v-for="table in columnsUfEfficient"
          :key="table.dataField"
          :data-field="table.dataField"
          :caption="table.caption"
          :width="table.width"
          :min-width="table.minWidth"
          :fixed="table.fixed"
          :fixed-position="table.fixedPosition"
          :allow-sorting="true"
        />
      </DxColumn>
      <DxColumn
        :fixed="true"
        fixed-position="right"
        alignment="center"
        cell-template="buttonsColumnTemplate"
        :width="70"
      />
      <template #buttonsColumnTemplate="data">
        <div class="action-buttons">
          <DxButton
            styling-mode="text"
            class="dx-button dx-widget dx-button-has-icon dx-button-mode-text button-search"
            type="success"
            icon="search"
            @click="viewPlan(data)"
          />
          <DxButton
            v-if="isAdmin"
            styling-mode="text"
            type="danger"
            class=""
            icon="trash"
            @click="removePlan(data)"
          />
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxDataGrid, DxColumn, DxSearchPanel, DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import { mapGetters } from 'vuex';

export default {
  name: 'ListByGroup',
  components: {
    DxButton,
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxPager,
    DxPaging,
  },
  props: {
    referencePlans: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tables: [
        {
          dataField: 'description',
          caption: 'Descripción del plan',
          fixed: true,
          width: 200,
          minWidth: 200,
          fixedPosition: 'left',
        },
        {
          dataField: 'species',
          caption: 'Especie',
          fixed: false,
          width: 120,
          minWidth: 120,
        },
        {
          dataField: 'variety',
          caption: 'Variedad',
          fixed: false,
          width: 120,
          minWidth: 120,
        },
        {
          dataField: 'duration',
          caption: 'Duración (días)',
          fixed: false,
          width: 120,
          minWidth: 120,
        },
        {
          dataField: 'production',
          caption: ' Producción (t/ha)',
          fixed: false,
          width: 120,
          minWidth: 120,
        },
        {
          dataField: 'cost',
          caption: ' Coste €/ha',
          fixed: false,
          width: 120,
          minWidth: 120,
        },
        {
          dataField: 'ufEfficient.n',
          caption: 'N/ha',
          fixed: false,
          minWidth: 60,
          group: 'ufEfficient',
        },
        {
          dataField: 'ufEfficient.p',
          caption: 'P₂O₅/ha',
          fixed: false,
          minWidth: 60,
          group: 'ufEfficient',
        },
        {
          dataField: 'ufEfficient.k',
          caption: 'K₂O/ha',
          fixed: false,
          minWidth: 60,
          group: 'ufEfficient',
        },
        {
          dataField: 'ufEfficient.c',
          caption: 'CaO/ha',
          fixed: false,
          minWidth: 60,
          group: 'ufEfficient',
        },
        {
          dataField: 'ufEfficient.m',
          caption: 'MgO/ha',
          fixed: false,
          minWidth: 60,
          group: 'ufEfficient',
        },
        {
          dataField: 'ufEfficient.s',
          caption: 'SO₃/ha',
          fixed: false,
          minWidth: 60,
          group: 'ufEfficient',
        },
      ],
    };
  },
  computed: {
    columnsSimple() {
      return this.tables.filter((table) => table.group === undefined);
    },
    columnsUfEfficient() {
      return this.tables.filter((table) => table.group === 'ufEfficient');
    },

    ...mapGetters('authentication', ['isAdmin']),
  },
  methods: {
    viewPlan(data) {
      this.$fa.logEvent('Utilidades - Planes de referencia', {
        id: data.data.data.id,
      });
      this.$f7router.navigate({
        name: 'reference-plans-detail',
        params: {
          id: data.data.data.id,
        },
      });
    },
    removePlan(data) {
      if (!this.isAdmin) return;

      this.$f7.dialog.confirm('¿Estás segura de que quieres eliminar este plan?',
        () => {
          this.$emit('remove-plan', data);
        });
    },
  },
};
</script>

<style scoped>
::v-deep.list-by-group-reference-plans .action-buttons .dx-button-content {
  padding: 2px !important;
}
::v-deep .dx-header-row td[role="columnheader"] {
  text-transform: none;
}
</style>

<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="$t('AnalysisGis.title')" />
    <GisGrid
      v-if="loaded"
      :custom-store="customStore"
    />
    <f7-row v-if="loaded && sectorIdLeftComparator !== '' && sectorIdRightComparator !== ''">
      <f7-col />
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="compare()"
        >
          {{ $t("AnalysisGis.compare") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import navbar from '../../components/NavBar.vue';
import GisGrid from '../../components/analisys/gis/gisGrid/index.vue';

export default {
  name: 'GisGridPage',
  components: {
    navbar,
    GisGrid,
  },
  data() {
    return {
      loaded: false,
      customStore: null,
    };
  },
  computed: {
    ...mapState('Plantation', ['plantations', 'plantationsFormatted', 'sectorIdLeftComparator', 'sectorIdRightComparator']),
  },
  methods: {
    async beforein() {
      this.$fa.setScreenName('Análisis > Gis');
      this.$f7.preloader.show();
      try {
        this.customStore = await this.getStore({ type: 'contactPlantation' });
        // this.setPlantationsFormatted(this.formattingPlantations());
        this.setSectorIdLeftComparator('');
        this.setSectorIdRightComparator('');
        this.resetComparatorsPlantationId();
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    formattingPlantations() {
      const plantationsFormatted = [];
      for (const plantation of this.plantations) {
        plantationsFormatted.push({
          id: plantation.id,
          companyId: plantation.company_id,
          company: plantation.company,
          name: plantation.name,
          province: plantation.province,
          explotation: plantation.explotation,
          parcel: plantation.parcel,
          sector: plantation.sector,
          campaign: plantation.campaign,
          variety: plantation.variety,
          initDate: plantation.init_date,
          endDate: plantation.end_date,
          species: plantation.species,
          status: this.getStatusCampaign(moment(plantation.init_date).format('YYYY-MM-DD'), moment(plantation.end_date).format('YYYY-MM-DD')),
          area: plantation.area,
          coordinates: plantation.coordinates,
          sectorId: plantation.sector_id,
          sectorIdLeftComparator: false,
          sectorIdRightComparator: false,
        });
      }
      return plantationsFormatted;
    },
    getStatusCampaign(initDate, endDate) {
      if (moment(this.actualDate).isBetween(initDate, endDate)) {
        return this.$t('status_campania_active');
      } if (endDate < this.actualDate) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    compare() {
      this.$fa.logEvent('Análisis - GIS- Comparar');
      this.$f7.views.main.router.navigate('/gisComparator/', { reloadCurrent: true });
    },
    ...mapActions('Plantation', ['fetchPlantations', 'setPlantationsFormatted', 'setSectorIdLeftComparator', 'setSectorIdRightComparator', 'resetComparatorsPlantationId']),
    ...mapActions('Pagination', ['getStore']),

  },
};
</script>

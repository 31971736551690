<template>
  <div>
    <div class="margin-top-half">
      <div
        class="content-devx"
      >
        <div class="grid-container">
          <!-- Nombres de las columnas de los atributos -->
          <div v-for="columnName in columnUserAttributes" :key="columnName" :class="{ 'col-email': columnName === 'DxClients_email' }">
            {{ $t(columnName) }}
          </div>
          <!-- valores de las filas que vamos a mostrar-->
          <div v-for="attribute in userAttributes" 
            :key="attribute"
            :class="[
              'container__item',
              attribute === 'client_email' ? 'col-email' : '',
            ]"
          >
            {{ actualUser[attribute] }}
          </div>
        </div>
      </div>
    </div>
    <f7-row no-gap>
      <f7-col width="50">
        <DxSystemParcelLocation />
        <div class="margin margin-right-half">
          <f7-row class="margin-top">
            <f7-col width="20">
              <f7-button
                class="dx-btn-back"
                type="default" 
                text-color="grey"
                styling-mode="outlined"
                name="ButtonCancel"
                @click="goToParcelSystem"
              >
                {{ $t("Button_go_to_back") }}
              </f7-button>
            </f7-col>

            <f7-col width="60" />
          </f7-row>
        </div>
      </f7-col>
      <f7-col width="50">
        <f7-block v-if="!loadedGIS">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>
        <div
          v-else
          class="margin-right-block"
        >
          <GISGeoJsonMap
            :zoom="zoom"
            :name-map="mainMap"
            :tree-locations="treeLocations"
            selected-min-height="610px"
          />
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import DxSystemParcelLocation from '../../systemParcelExplotaciones/SistemParceelLocation/index.vue';
import GISGeoJsonMap from '../../Gis/GISGeoJsonMap/index.vue';
import optionsList from '../../../json/fakeListLayer.json';

export default {
  name: 'DxSystemParcelViewClient',
  components: {
    DxSystemParcelLocation,
    GISGeoJsonMap,
  },
  data() {
    return {
      VarietyFormated: [],
      pageSizes: [5, 10, 20],
      isEdit: false,
      popupVisible: false,
      optionsList,
      loadedGIS: false,
      zoom: 17,
      mainMap: 'mainMap',
      // Añadir a este array el nombre de columna si se pide alguno nuevo
      columnUserAttributes: [
      'DxClients_client',
      'parcelViewClient_number_phone',
      'DxClients_email',
      'DxClients_provincia',
      'DxClients_localidad',
      'DxClients_pc'
    ],
      // Añadir a este array el atributo nuevo si se pide uno nuevo en un futuro
      userAttributes: ['name', 'mobile_phone', 'client_email', 'province', 'city', 'postal_code']
    };
  },
  computed: {
    ...mapState('Explotaciones', ['actualUser']),
    ...mapState('Plantation', ['treeLocations']),
  },
  async beforeMount() {
    try {
      this.$fa.logEvent('Clientes - Parcelario');
      await this.fetchLocationsByCompany(this.actualUser.id);
      this.loadedGIS = true;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    }
  },
  methods: {
    ...mapActions('Plantation', ['fetchLocationsByCompany']),
    goToParcelSystem() {
      this.$f7.views.main.router.navigate('/systemParcelClients/', { reloadCurrent: true });
    },
    goToClientSales() {
      this.$f7.views.main.router.navigate('/viewClient/systemParcelClients/', { reloadCurrent: true });
    },
  },
};
</script>
<style lang="scss" scoped>
@import './SystemParcelViewClient.styles.scss';
</style>

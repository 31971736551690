<template>
  <div
    class="content-devx"
  >
    <DxDataGrid
      id="gisGrid-grid"
      ref="gisGrid"
      :data-source="customStore"
      :show-borders="true"
      :remote-operations="true"
      :column-auto-width="true"
      :show-colon="false"
      @exporting="onExporting"
      @contentReady="onInit"
    >
      <DxStateStoring
        :enabled="false"
        type="localStorage"
        storage-key="gisGrid"
      />
      <DxHeaderFilter :visible="true" />
      <DxGroupPanel :visible="false" />
      <DxGrouping
        :auto-expand-all="true"
      />
      <DxPaging :page-size="gridSettings.pageSize" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        info-text="{2} items"
      />
      <DxExport
        :enabled="false"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        v-if="hasATC"
        data-field="owner_fullname"
        :caption="$t('DxAtc_atc')"
      />
      <DxColumn
        data-field="company"
        :group-index="0"
        caption=""
      />
      <DxColumn
        data-field="name"
        :caption="$t('searchEngine_description')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="province"
        :caption="$t('searchEngine_province')"
        alignment="left"
      />
      <DxColumn
        data-field="explotation"
        :caption="$t('searchEngine_farm')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="parcel"
        :caption="$t('searchEngine_parcel')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="sector"
        :caption="$t('searchEngine_sector')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="status"
        :caption="$t('searchEngine_state')"
        alignment="left"
        :header-filter="{ dataSource: dataTaskStatus }"
        cell-template="cellTemplate"
      />
      <DxColumn
        data-field="species"
        :caption="$t('searchEngine_botanical_species')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="variety"
        :caption="$t('searchEngine_variety')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="initDate"
        format="dd/MM/yyyy"
        :caption="$t('searchEngine_init_date')"
        alignment="right"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="endDate"
        :caption="$t('searchEngine_end_date')"
        alignment="right"
        :allow-header-filtering="false"
        format="dd/MM/yyyy"
      />
      <DxColumn
        :data-field="$t('AnalysisGis.left')"
        alignment="center"
        :allow-header-filtering="false"
        cell-template="leftComparatorTemplate"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
        :width="50"
      />
      <DxColumn
        :data-field="$t('AnalysisGis.right')"
        alignment="center"
        :allow-header-filtering="false"
        cell-template="rightComparatorTemplate"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
        :width="50"
      />
      <template #cellTemplate="{ data: dataCampaign }">
        <div :class="dataCampaign.data.status">
          {{ dataCampaign.data.status }}
        </div>
      </template>
      <template #leftComparatorTemplate="{ data: plantation }">
        <div>
          <DxCheckBox
            :value="plantation.data.id === LeftComparatorPlantationId"
            @valueChanged="comparatorChanged($event, 'left', plantation)"
          />
        </div>
      </template>
      <template #rightComparatorTemplate="{ data: plantation }">
        <DxCheckBox
          :value="plantation.data.id === RightComparatorPlantationId"
          @valueChanged="comparatorChanged($event, 'right', plantation)"
        />
      </template>
      <DxScrolling
        column-rendering-mode="standard"
        :visible="true"
      />
    </DxDataGrid>
  </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxExport,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxStateStoring,
  DxScrolling,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import settings from '../../../../js/commonSettings';

export default {
  name: 'GisGrid',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxExport,
    DxGrouping,
    DxGroupPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxScrolling,
    DxPager,
    DxPaging,
    DxCheckBox,
  },
  props: {
    plantations: {
      type: Array,
      default: () => [],
    },
    customStore: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      dataTaskStatus: [{
        text: this.$t('status_campania_active'),
        value: 'ACTIVA',
      }, {
        text: this.$t('status_campania_end'),
        value: 'FINALIZADA',
      },
      {
        text: this.$t('status_campania_pending'),
        value: 'PENDIENTE',
      }],
      actualDate: moment().format('YYYY-MM-DD'),
      format: 'shortDate',
    };
  },
  computed: {
    hasATC() {
      return this.user?.user?.contact?.admin ?? false;
    },
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('Plantation', ['LeftComparatorPlantationId', 'RightComparatorPlantationId']),
    ...mapState('authentication', ['user']),
  },
  methods: {
    onInit(e) {
      const allPlantations = [];
      e.component.getDataSource()._items.forEach((item) => {
        allPlantations.push(...item.items);
      });

      this.setPlantationsFormatted(this.formattingPlantations(allPlantations));
      Array.from(document.getElementsByClassName('dx-group-cell')).forEach((el) => {
        el.innerHTML = el.innerHTML.split(':')?.[1]?.trim() ?? el.innerHTML;
      });
    },
    formattingPlantations(plts) {
      const plantationsFormatted = [];
      for (const plantation of plts) {
        plantationsFormatted.push({
          id: plantation.id,
          companyId: plantation.company_id,
          company: plantation.company,
          name: plantation.name,
          province: plantation.province,
          explotation: plantation.explotation,
          parcel: plantation.parcel,
          sector: plantation.sector,
          campaign: plantation.campaign,
          variety: plantation.variety,
          initDate: plantation.init_date,
          endDate: plantation.end_date,
          species: plantation.species,
          status: '',
          area: plantation.area,
          coordinates: plantation.coordinates,
          sectorId: plantation.sector_id,
          sectorIdLeftComparator: false,
          sectorIdRightComparator: false,
        });
      }
      return plantationsFormatted;
    },
    comparatorChanged(e, comparator, plantation) {
      if (e.event) {
        if (comparator === 'left') {
          this.updateLeftComparatorPlantationsFormatted(plantation.data.id);
        } else {
          this.updateRightComparatorPlantationsFormatted(plantation.data.id);
        }
      }
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('GisGrid');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'GisGrid.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Plantation', ['updateLeftComparatorPlantationsFormatted', 'updateRightComparatorPlantationsFormatted', 'setPlantationsFormatted']),
  },

};
</script>
<style lang="scss" scoped>
@import './GisGrid.styles.scss';
</style>
